<template>
<div class="w-screen h-screen bg-coolGray-900">

   <div class="w-full h-full flex items-center justify-center bg-coolGray-900 bg-cover bg-bottom" style="background-image:url(/imgs/background.png)" v-if="!connected">
      
      <!-- Loading -->
      <div class="text-white text-2xl" v-if="!socked"><i class="bx bx-loader-alt bx-spin"></i></div>

      <!-- Login form -->
      <div class="p-12 bg-coolGray-800 rounded-xl text-white bg-opacity-80" v-else>
         <div class="text-2xl">Seja bem vindo!</div>
         <div class="text">A sessão já vai começar</div>
         <div class="mt-8 flex">
            <div>
               <img class="border-2 border-white rounded-xl" width="128" height="128" :src="`/imgs/pic${picture}.png`" alt="">
            </div>
            <div class="flex flex-col flex-1 w-72 ml-8">
               <div>Nome:</div>
               <input class="mt-2 px-2 py-1 bg-coolGray-900" type="text" v-model="name">
               <div class="mt-4 mr-auto py-2 px-12 bg-indigo-500 text-white cursor-pointer" @click="join" v-if="!connecting">Entrar</div>
               <div class="mt-4 mr-auto py-2 px-12 bg-indigo-500 text-white cursor-pointer" v-else><i class="bx bx-loader-alt bx-spin"></i></div>
            </div>
         </div>
      </div>

   </div>

   <div class="w-full h-full flex flex-col md:flex-row" v-show="connected">
      
      <div class="flex-1 p-4 flex flex-col">

         <!-- Movie -->
         <div class="flex-1 bg-black rounded" ref="wrapper">
            <video class="w-full h-full" src="https://s3.wasabisys.com/o-o/QN7JILJR5KS0OBU0.mp4" ref="player"></video>
         </div>

         <!-- Leader controls -->
         <div class="p-4 flex" v-if="isLeader">
            <div class="p-2 text-xl text-white cursor-pointer" @click="pause"><i class="bx bx-pause"></i></div>
            <div class="ml-4 p-2 text-xl text-white cursor-pointer" @click="play"><i class="bx bx-play"></i></div>
            <div class="flex flex-1 ml-4 items-center">
               <input style="width:100%" type="range" min="0" max="1000" v-model="timePer" @change="updateTime" />
            </div>
            <div class="flex items-center ml-4">
               <i class="mr-2 bx bx-volume-full text-white text-xl"></i>\
               <input type="range" min="0" max="100" value="100" @change="setVolume" />
            </div>
            <div class="ml-4 p-2 text-xl text-white cursor-pointer" @click="fullscreen"><i class="bx bx-fullscreen"></i></div>
         </div>

         <!-- Non Leader controls -->
         <div class="mt-4 px-4 flex" v-else>
            <div class="ml-auto flex items-center">
               <i class="mr-2 bx bx-volume-full text-white text-xl"></i>
               <input class="text-green-500" type="range" min="0" max="100" value="100" @change="setVolume" />
            </div>
            <div class="ml-4 p-2 text-xl text-white cursor-pointer" @click="fullscreen"><i class="bx bx-fullscreen"></i></div>
         </div>

      </div>

      <!-- People list -->
      <div class="flex flex-col m-4 p-4 bg-coolGray-800 rounded">
         <div class="flex-1">
            <div class="mb-2 flex items-center text-white" v-for="client of clients" :key="client.id">
               <img class="border border-white rounded-full" width="42" :src="`/imgs/pic${client.picture}.png`" alt="">
               <div class="flex-1 ml-2"><i class="mr-2 bx bx-crown" v-if="client.leader"></i>{{client.name}}</div>
               <div class="ml-4">
                  <i class="bx bx-check-circle text-green-500" v-if="client.ready"></i>
                  <i class="bx bx-x-circle text-red-500" v-else></i>
               </div>
            </div>
            <div class="mt-4 mr-auto py-2 px-12 bg-indigo-500 text-white cursor-pointer" @click="ask" v-if="isLeader">Ask ready</div>
         </div>
         <div class="flex">
            <div class="p-2 mx-auto bg-white rounded cursor-pointer" @click="toggleMute" v-if="!muted">
               <i class="mr-2 bx bx-microphone-off"></i> Mutar
            </div>
            <div class="p-2 mx-auto bg-white rounded cursor-pointer" @click="toggleMute" v-else>
               <i class="mr-2 bx bx-microphone"></i> Desmutar
            </div>
         </div>
      </div>

   </div>
   <div v-show="false">
      <video ref="localVideo" oncontextmenu="return false;"></video>
   </div>
</div>
</template>

<script>
import io from "socket.io-client";
const socket = io("https://edge.o-o.club:3000");

import WebRTC from "@/modules/webrtc";

export default { 
   data: () => ({
      // Data
      id: "",
      name: "",
      picture: 1,

      // References
      player: null,
      wrapper: null,

      // State
      socked: false,
      connected: false,
      connecting: false,
      muted: false,

      // Player manager
      isLeader: false,
      clients: [],
      time: 0,
      timePer: 0,

      // Webrtc
      webrtc: null,
      remotes: []
   }),

   computed: {
      paused(){
         if( !this.$refs.player ) return true;
         return this.$refs.player.paused;
      },

      state(){
         let ready = true;
         this.clients.forEach((c) => {
            if(!c.ready){
               ready = false;
            }
         });

         return (ready) ? "ready" : "not ready";
      }
   },

   mounted(){
      this.picture = 1 + (~~(Math.random()*16));


      // Socket connection
      socket.on("connect", () => {
         this.socked = true;
         this.id = socket.id;
      });


      // Client manager
      socket.on("clients", (clients) => {
         this.clients = clients;
         if( this.isLeader ){
            socket.emit("ask_ready");
         }
      });

      socket.on("leader", () => {
         this.isLeader = true;
         socket.emit("pause");
         socket.emit("sync_time", this.time);
         socket.emit("ask_ready");
      });

      socket.on("new_client", () => {
         if( this.isLeader ){
            socket.emit("pause");
            socket.emit("sync_time", this.time);
            socket.emit("ask_ready");
         }
      });


      // Ready manager
      socket.on("ready", (id) => {
         let client = this.clients.find((v) => v.id == id);
         if( !client ) return;
         client.ready = true;
      });
      socket.on("unready", (id) => {
         let client = this.clients.find((v) => v.id == id);
         if( !client ) return;
         client.ready = false;
         if( this.isLeader ){
            socket.emit("pause");
            socket.emit("sync_time", this.time);
            socket.emit("ask_ready");
         }
      });
      socket.on("ask_ready", () => {
         if( this.$refs.player.readyState == 1 ){
            socket.emit("ready");
         }
      });

      socket.on("sync_time", (time) => {
         this.$refs.player.pause();
         this.$refs.player.currentTime = time;
         this.time = time;
      });

      socket.on("play", () => {
         this.$refs.player.play();
      });

      socket.on("pause", () => {
         this.$refs.player.pause();
      });

      this.$refs.player.addEventListener("canplay", (ev) => {
         socket.emit("ready");
      })
      
      this.$refs.player.addEventListener("waiting", (ev) => {
         socket.emit("unready");
      })

      this.$refs.player.addEventListener("timeupdate", (ev) => {
         this.time = ev.target.currentTime;
         this.timePer = ~~((this.time / this.$refs.player.duration)*1000);
      });
   },

   methods: {
      join(){
         this.connected = true;
         socket.emit("join", { name: this.name, picture: this.picture });

         this.webrtc = new WebRTC({
            url: 'https://edge.taqui.online/webrtc',
            localVideoEl: this.$refs.localVideo,
            remoteVideosEl: '',
            autoRequestMedia: true,
            debug: true,
            autoAdjustMic: false,
            media: {
               video: false,
               audio: true
            }
         });

         this.webrtc.on('readyToCall', () => {
            this.webrtc.joinRoom("cinema");
         });
      },


      play(){
         socket.emit("play");
      },
      pause(){
         socket.emit("pause");
      },

      ask(){
         socket.emit("ask_ready");
      },

      fullscreen(){
         let elem = this.$refs.wrapper;
         if (elem.requestFullscreen) {
            elem.requestFullscreen();
         } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
         } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
         } else if (elem.msRequestFullscreen) { 
            elem.msRequestFullscreen();
         }
      },

      setVolume(ev){
         let val = ev.target.value;
         let vol = parseInt(val)/100;
         this.$refs.player.volume = vol;
      },

      updateTime(ev){
         let val = ev.target.value;
         let per = parseInt(val)/1000;
         let time = this.$refs.player.duration * per;
         this.time = time;
         
         Object.values(this.clients).forEach((c) => c.ready = false);
         socket.emit("sync_time", time);
      },

      toggleMute(){
         if( this.muted ) this.webrtc.unmute();
         else this.webrtc.mute();
         this.muted = !this.muted;
      }
   }
}
</script>